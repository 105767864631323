@use '../util/' as *;

.subscribe-form {
  h4 {
    margin-top: rem(35);
    margin-bottom: rem(10) !important;
  }

  label {
    line-height: rem(19) !important;
    margin-bottom: rem(10);

    font-style: normal;
    font-size: rem(14);
    color: var(--fg-text);
  }

  form {
    display: flex;
    align-items: center;

    max-width: rem(381);
  }

  &__input {
    background-color: hsl(240, 3%, 14%);
    color: var(--bg-color);
    border: none;
    outline: none;
    padding: rem(8) rem(5) rem(8) rem(15);
    
    flex-grow: 1;

    font-family: inherit;
  }

  &__button {
    width: rem(40);
    height: rem(40);

    background-color: var(--primary-color);
    color: var(--bg-color);

    transition: all .3s ease-in-out;

    &:hover {
        background-color: var(--bg-color);
        color: var(--primary-color);
    }
  }

}
