@use '../util/' as *;

.logo-and-socials {
  padding: rem(45) rem(100);
  margin: rem(45);
  margin-right: rem(100);
  margin-left: rem(100);
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: rem(1) dashed var(--fg2-color);
  border-bottom: rem(1) dashed var(--fg2-color);

  // &__logo {
  // }

  &__socials {

    display: flex;
    flex-direction: row;
    gap: rem(2);

    a {

      height: rem(35);
      width: rem(35);

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;

      transition: all .3s ease-in-out;

      i {
        color: var(--fg-text);
        transition: all .3s ease-in-out;
      }

      &:hover {
        background-color: var(--primary-color);

        i {
          color: var(--bg-color);
        }
      }
    }
  }

  @include breakpoint-down("medium") {
    flex-direction: column;
    gap: rem(45);
    padding: rem(45) 0;
  }

  @include breakpoint-up("medium") {
    width: 80%;
  }
}
