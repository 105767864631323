@use '../util/' as *;

.tiles {
  max-width: rem(1115);
  margin: rem(55) auto;

  @include breakpoint-down("medium") {
    padding: 0 rem(15);
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: rem(30);

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: rem(48);
    text-align: center;

    span {
      border-bottom: rem(4) solid var(--primary-color);
    }

    padding-bottom: rem(5);
  }

  &__tile {

    @include breakpoint-up("medium") {
      flex-grow: 1;
      width: 48%;
    }

    @include breakpoint-up("large") {
      flex-grow: 1;
      width: 22%;
    }

    .tile__icon {
      svg {
        color: var(--primary-color);
        stroke: var(--primary-color);
      }
    }

    .tile__title {
      margin-top: rem(16);

      font-style: normal;
      font-weight: bold;
      font-size: rem(28);
      line-height: rem(36);
    }

    .tile__content {
      font-style: normal;
      font-weight: normal;
      font-size: rem(16);
      line-height: rem(24);
      // text-align: justify;

      margin-bottom: 0;
      
      @media only screen and (max-width: 779px) {
        padding-right: rem(70);
      }

    }
  }

}
