html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-gudea);
}

.ul-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.button-reset {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.hidden {
  display: none !important;
}
