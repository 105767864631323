@use '../util/' as *;

.carousel {
  max-width: rem(1920);
  margin: 0 auto;

  &__img1 {
    background-image: url("../assets/carousel/carousel-1.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: rem(538);

    @include breakpoint-down('medium') {
      height: rem(300);
    }
  }

  &__img2 {
    background-image: url("../assets/carousel/carousel-2.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: rem(538);

    @include breakpoint-down('medium') {
      height: rem(300);
    }
  }

  &__quote {
    font-style: normal;
    font-weight: bold;
    font-size: rem(32);
    line-height: rem(39);

    text-shadow: 0px 3px 4px #292929, 0 0 9px #000;

    @include breakpoint-down('medium') {
      font-size: rem(22);
      line-height: rem(29);
    }
  }

  &__button {
    padding: rem(7) rem(25);
    background-color: var(--primary-color);
    color: var(--bg-color);

    margin: 0 auto;

    font-size: rem(17);

    transition: all .2s ease-in-out;
    &:hover {
      box-shadow: rem(2) rem(2) rem(13) #202020;
      color: inherit;
    }
  }

  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 4.5rem;
    height: 4.5rem;
  }

  .carousel-caption {
    bottom: 50%;
    transform: translateY(50%);
  }

  .carousel-indicators {
    .active {
      background-color: var(--primary-color);
    }
  }

  .carousel-control-next, .carousel-control-prev {
    width: 7%;
  }

}
