@use "../util/" as *;

.team-wrapper {
  margin-bottom: rem(100);

  .team-header {
    max-width: rem(1440);
    min-height: rem(130);
    margin: 0 auto;

    background-image: url(../assets/decorators/img-sp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: rem(32);
      font-weight: 700;
      line-height: rem(39);
      color: var(--bg-color);
      text-shadow: 1px 1px 2px #3a3a3c;
    }
  }

  .team {
    max-width: rem(1115);
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr;
    gap: rem(79);

    &__title {
      text-align: center;
      margin: rem(80) 0;

      font-size: rem(48);
      font-weight: 700;
      line-height: rem(36);

      span {
        border-bottom: rem(4) solid var(--primary-color);
      }
    }

    &__card {
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      border-bottom: rem(4) solid #3a3a3c;

      @include breakpoint-down("small") {
        flex-direction: column;
      }

      &--img {
        max-height: 368px;
      }

      &--info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: rem(53) rem(56) rem(47) rem(48);
        min-height: rem(368);

        p,
        a {
          word-break: break-word;
        }

        a {
          color: var(--primary-color);
        }

        @include breakpoint-down("small") {
          gap: rem(25);
          padding: rem(50) rem(10);
        }

        & .title {
          font-size: rem(28);
          font-weight: 700;
          line-height: rem(36);
          margin-bottom: rem(5);
        }

        & .subtitle {
          font-size: rem(18);
          font-weight: normal;
          line-height: rem(26);
          margin: 0;
        }

        & .bio {
          font-size: rem(16);
          font-weight: normal;
          line-height: rem(26);
          margin: 0;
        }

        & .mail {
          display: flex;
          align-items: center;
          gap: rem(15);

          @include breakpoint-down("small") {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
          }

          & p {
            margin: 0;
            line-height: 1;
          }
        }
      }
    }
  }
}
