@use '../util' as *;

.navheader {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;

  height: rem(96);
  padding-right: rem(148);
  padding-left: rem(32);

  background-color: var(--bg-color);

  @include breakpoint-down("medium") {
    padding-right: rem(32);
  }

  &__toggler {
    display: none;

    @include breakpoint-down("medium") {
      display: inline-block;
    }
  }

  &__img {
    max-width: rem(395);
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-down("medium") {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }

    @include breakpoint-up('large') {
      margin-left: 0;
    }
  }

  &__links {
    margin-left: auto;

    @include breakpoint-down("medium") {
      display: none;
    }

    li {
      float: left;

      margin-right: rem(20);

      &:last-child {
        margin-right: 0;
      }
    }

    li a {
      display: block;
      color: var(--txt-color);
      text-align: center;
      padding: rem(3) 0;
      text-decoration: none;

      position: relative;
    }

    li a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rem(2.11);
      background-color: var(--primary-color);
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    li a:hover::after,
    li a:focus::after {
      opacity: 1;
    }
  }
}
