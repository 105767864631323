h1,
h2,
h3 {
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}
