@use '../util/' as *;

.publicacoes-wrapper {
  padding-top: rem(35);

  .publicacoes {
    max-width: rem(1115);
    margin: rem(55) auto;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: rem(28);

    @include breakpoint-down("medium") {
      flex-direction: column;
      align-items: center;
      padding: rem(15);
    }

    &__img {
      max-width: rem(544);
      min-width: rem(300);
      width: 100%;
      height: rem(308);

      position: relative;

      .img {
        width: 100%;
        height: 100%;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
      }

      .img:hover {
        background: linear-gradient(to bottom, transparent -100%, black 100%)
          url(intial) !important;
      }

      span {
        position: absolute;
        bottom: 0;
        left: 0;

        font-style: normal;
        font-weight: bold;
        font-size: rem(28);

        color: var(--bg-color);

        margin: 0 rem(20) rem(24) rem(20);

        border-top: rem(4) solid var(--primary-color);
      }
    }

    &__links {
      max-width: rem(540);

      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;

        @include breakpoint-down("medium") {
          gap: rem(15);
        }
      }
    }

    &__link {
      span {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: rem(16);
        letter-spacing: rem(0);
      }

      a {
        font-style: normal;
        font-weight: bold;
        font-size: rem(28);
        line-height: rem(24);
        color: var(--txt-color);
      }
    }
  }
}
