@use '../util/' as *;

.meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  color: var(--fg-text);
  font-size: rem(14);

  @include breakpoint-up("medium") {
    width: 80%;
  }

  @include breakpoint-down("medium") {
    flex-direction: column;
    gap: rem(10);
    padding: rem(20) 0;
  }

  a {
      color: var(--bg-color);
  }

  a:hover {
      color: var(--primary-color);
  }

  span {
    text-align: center;
  }
}
