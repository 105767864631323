@use '../util/' as *;

.offcanvas {
  background-color: var(--fg-color);
  color: var(--bg-color);

  a,
  a:active,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  &-start {
    width: 75vw;

    @include breakpoint-up("medium") {
      width: 50vw;
    }

  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: rem(20);
  }

  &__img {
    margin-left: auto;
    max-width: rem(180);
  }

  &__toggler {
    margin-left: auto;

    height: rem(50);
    width: rem(50);
    background-color: var(--primary-color);

    position: absolute;
    top: 0;
    right: 0;

    i {
      color: var(--bg-color);
    }

    transform: translateX(rem(50));
  }

  &__link {
    padding: rem(15) 0;
    border-bottom: rem(1) solid var(--fg2-color);

    font-size: rem(18);

    &:last-child {
      border-bottom: none;
    }

    &__a:hover {
      color: var(--primary-color) !important;
    }
  }
}
