@use '../util/' as *;

.contact {

  &__title {
    padding: rem(15);
    display: flex;
    justify-content: center;

    h1 {
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: rem(32);
      line-height: rem(40);
      color: var(--fg-color);

      text-align: center;

      border-bottom: 4px solid var(--primary-color);
      padding-bottom: rem(10);
    }
  }

  &__form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0 auto;

    max-width: rem(910);

    .form__col {
      display: flex;
      flex-direction: column;
      gap: rem(10);

      max-width: rem(430);
      width: 100%;

      margin: 0 rem(10);

      label {
        display: block;
        color: var(--fg-text);
        font-size: rem(15);
      }

      input,
      textarea {
        background-color: #ebebeb;
        border-color: transparent;
        box-shadow: none;
        padding: rem(5);
        margin: 0 !important;
        width: 100%;
      }

      textarea {
        height: calc(100% - 22px);
      }
    }

    .form__actions {
      margin: rem(30) rem(10);

      button {
        background-color: var(--primary-color);
        color: var(--bg-color);

        padding:  rem(5) 0;

        border-color: transparent;
      }
    }
  }
}
