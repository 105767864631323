@use '../util/' as *;

.footer {
  padding: rem(70) 0 rem(57);

  background-color: var(--fg-color);
  color: var(--bg-color);

  &-wrapper {
    max-width: rem(1115);
    margin: 0 auto;
  }

  // display: flex;
  // justify-content: center;
  // flex-wrap: wrap;
  // gap: rem(40);

  // display: grid;
  // gap: rem(40);
  // grid-template-columns: repeat(auto-fit, 237px);
  // // grid-template-rows: auto auto;
  // justify-content: center;

  &__col {
    max-width: rem(237);
    display: flex;
    flex-direction: column;
    gap: rem(23);
    margin: rem(15);

    &--title {
      font-size: rem(18);
      font-weight: 700;
      line-height: rem(36);
      margin: 0;
    }

  }

  &__meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: rem(1070);
    padding-top: rem(25);
    margin-top: rem(40);

    font-size: rem(13);
    font-weight: normal;

    border-top: rem(1) solid #9A9A9B;

    @include breakpoint-down('small') {
      flex-direction: column;
      gap: rem(15);
    }

    @include breakpoint-down('medium') {
      padding: rem(25) rem(15) 0;
    }

    & > div {
      display: inline-block;
    }

    &--rights {
      p {
        margin: 0;
        text-align: center;
      }
    }

    &--developer {
      img {
        margin-left: rem(13);
      }
    }

  }

  &__widget {
    list-style-type: none;
    margin: 0;
    padding: 0;

    font-size: rem(13);
    font-weight: normal;
    line-height: rem(21);
    color: #9a9a9b;

    &--address {
      padding: rem(2) 0;
    }

    &--contact {
      a {
        color: inherit;

        &:hover {
          color: var(--primary-color);
          transition: color .2s ease-in-out;
        }

      }
    }

    &--socials {
      display: inline;
      margin-right: rem(10);

      .facebook {
        svg {
          .a {
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
          .b,
          .c {
            stroke: none;
          }
          .c {
            fill: #b4b4b1;
          }

          &:hover {
            .c {
              fill: var(--primary-color);
              transition: fill 0.2s ease-in-out;
            }
          }
        }
      }

      .instagram {
        svg {
          .a {
            fill: #b4b4b4;
          }

          &:hover {
            .a {
              fill: var(--primary-color);
              transition: fill .2s ease-in-out;
            }
          }

        }
      }

      .linkedin {
        svg {
          height: rem(22);
          color: #b4b4b4;

          &:hover {
            color: var(--primary-color);
            transition: color .2s ease-in-out;
          }
        }
      }
    }

    &--item {

      padding: rem(7) 0;
      border-bottom: 1px solid #9A9A9B;

      a {
        color: #9A9A9B;

        &:hover {
          color: var(--primary-color);
          transition: color .2s ease-in-out;
        }

      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border: 0;
      }

    }

    &.contact {

      display: flex;
      flex-direction: column;

      input {
        margin-bottom: rem(10);
        height: rem(37);
        padding-inline-start: rem(13);
        background-color: #232325;
        border: transparent;
        color: var(--bg-color);
      }

      textarea {
        margin-bottom: rem(20);
        height: rem(119);
        padding-inline-start: rem(13);
        background-color: #232325;
        border: transparent;
        color: var(--bg-color);
      }

      button {
        align-self: start;
        height: rem(37);
        padding: 0 rem(15);

        background-color: var(--primary-color);
        color: var(--bg-color);
        border: transparent;
      }

    }
  }
}
