@use "../util/" as *;

.office-wrapper {
  .office-header {
    max-width: rem(1440);
    min-height: rem(130);
    margin: 0 auto;

    background-image: url(../assets/decorators/img-sp.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: rem(32);
      font-weight: 700;
      line-height: rem(39);
      color: var(--bg-color);
      text-shadow: 1px 1px 2px #3a3a3c;
    }
  }

  .office {
    max-width: rem(1115);
    margin: 0 auto;
    margin-bottom: rem(79);

    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      text-align: center;
      margin: rem(80) auto;

      font-size: rem(48);
      font-weight: 700;
      line-height: rem(60);

      display: inline-block;

      border-bottom: rem(4) solid var(--primary-color);
    }

    &__card {
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      margin: rem(30) 0;
      border-bottom: rem(4) solid #3a3a3c;

      @include breakpoint-down("small") {
        flex-direction: column;
      }

      &--info {
        padding: rem(53) rem(50) rem(40) rem(48);
        @include breakpoint-down("small") {
          padding: rem(20);
        }
      }
    }

    &__gallery {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: rem(19);
    }

    &__clients {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--title {
        text-align: center;
        margin: rem(80) auto;

        font-size: rem(48);
        font-weight: 700;
        line-height: rem(60);

        display: inline-block;

        border-bottom: rem(4) solid var(--primary-color);
      }

      &--logos {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: rem(20);

        img {
          max-width: rem(200);
          height: auto;
        }
      }
    }
  }
}
