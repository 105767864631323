:root {
  --font-gudea: "Gudea", sans-serif;
}

:root {
  --primary-color: hsl(0, 100%, 50%);
  --txt-color: hsl(0, 0%, 6%);
  --bg-color:	hsl(0, 0%, 100%);
  --fg-color: hsl(240, 2%, 18%);
  --fg2-color: hsla(0, 0%, 100%, 0.1);
  --fg-text: hsl(0, 0%, 71%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-gudea);
}

.ul-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.button-reset {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.hidden {
  display: none !important;
}

h1,
h2,
h3 {
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.navheader {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  height: 6rem;
  padding-right: 9.25rem;
  padding-left: 2rem;
  background-color: var(--bg-color);
}
@media (max-width: 73.3125em) {
  .navheader {
    padding-right: 2rem;
  }
}
.navheader__toggler {
  display: none;
}
@media (max-width: 73.3125em) {
  .navheader__toggler {
    display: inline-block;
  }
}
.navheader__img {
  max-width: 24.6875rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 73.3125em) {
  .navheader__img {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}
@media (min-width: 73.375em) {
  .navheader__img {
    margin-left: 0;
  }
}
.navheader__links {
  margin-left: auto;
}
@media (max-width: 73.3125em) {
  .navheader__links {
    display: none;
  }
}
.navheader__links li {
  float: left;
  margin-right: 1.25rem;
}
.navheader__links li:last-child {
  margin-right: 0;
}
.navheader__links li a {
  display: block;
  color: var(--txt-color);
  text-align: center;
  padding: 0.1875rem 0;
  text-decoration: none;
  position: relative;
}
.navheader__links li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.131875rem;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}
.navheader__links li a:hover::after,
.navheader__links li a:focus::after {
  opacity: 1;
}

.offcanvas {
  background-color: var(--fg-color);
  color: var(--bg-color);
}
.offcanvas a,
.offcanvas a:active,
.offcanvas a:visited {
  text-decoration: none;
  color: inherit;
}
.offcanvas-start {
  width: 75vw;
}
@media (min-width: 40em) {
  .offcanvas-start {
    width: 50vw;
  }
}
.offcanvas__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;
}
.offcanvas__img {
  margin-left: auto;
  max-width: 11.25rem;
}
.offcanvas__toggler {
  margin-left: auto;
  height: 3.125rem;
  width: 3.125rem;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(3.125rem);
}
.offcanvas__toggler i {
  color: var(--bg-color);
}
.offcanvas__link {
  padding: 0.9375rem 0;
  border-bottom: 0.0625rem solid var(--fg2-color);
  font-size: 1.125rem;
}
.offcanvas__link:last-child {
  border-bottom: none;
}
.offcanvas__link__a:hover {
  color: var(--primary-color) !important;
}

.carousel {
  max-width: 120rem;
  margin: 0 auto;
}
.carousel__img1 {
  background-image: url("../assets/carousel/carousel-1.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 33.625rem;
}
@media (max-width: 73.3125em) {
  .carousel__img1 {
    height: 18.75rem;
  }
}
.carousel__img2 {
  background-image: url("../assets/carousel/carousel-2.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 33.625rem;
}
@media (max-width: 73.3125em) {
  .carousel__img2 {
    height: 18.75rem;
  }
}
.carousel__quote {
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4375rem;
  text-shadow: 0px 3px 4px #292929, 0 0 9px #000;
}
@media (max-width: 73.3125em) {
  .carousel__quote {
    font-size: 1.375rem;
    line-height: 1.8125rem;
  }
}
.carousel__button {
  padding: 0.4375rem 1.5625rem;
  background-color: var(--primary-color);
  color: var(--bg-color);
  margin: 0 auto;
  font-size: 1.0625rem;
  transition: all 0.2s ease-in-out;
}
.carousel__button:hover {
  box-shadow: 0.125rem 0.125rem 0.8125rem #202020;
  color: inherit;
}
.carousel .carousel-control-next-icon, .carousel .carousel-control-prev-icon {
  width: 4.5rem;
  height: 4.5rem;
}
.carousel .carousel-caption {
  bottom: 50%;
  transform: translateY(50%);
}
.carousel .carousel-indicators .active {
  background-color: var(--primary-color);
}
.carousel .carousel-control-next, .carousel .carousel-control-prev {
  width: 7%;
}

.tiles {
  max-width: 69.6875rem;
  margin: 3.4375rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.875rem;
}
@media (max-width: 73.3125em) {
  .tiles {
    padding: 0 0.9375rem;
  }
}
.tiles__title {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  padding-bottom: 0.3125rem;
}
.tiles__title span {
  border-bottom: 0.25rem solid var(--primary-color);
}
@media (min-width: 40em) {
  .tiles__tile {
    flex-grow: 1;
    width: 48%;
  }
}
@media (min-width: 73.375em) {
  .tiles__tile {
    flex-grow: 1;
    width: 22%;
  }
}
.tiles__tile .tile__icon svg {
  color: var(--primary-color);
  stroke: var(--primary-color);
}
.tiles__tile .tile__title {
  margin-top: 1rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.25rem;
}
.tiles__tile .tile__content {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}
@media only screen and (max-width: 779px) {
  .tiles__tile .tile__content {
    padding-right: 4.375rem;
  }
}

.publicacoes-wrapper {
  padding-top: 2.1875rem;
}
.publicacoes-wrapper .publicacoes {
  max-width: 69.6875rem;
  margin: 3.4375rem auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  gap: 1.75rem;
}
@media (max-width: 73.3125em) {
  .publicacoes-wrapper .publicacoes {
    flex-direction: column;
    align-items: center;
    padding: 0.9375rem;
  }
}
.publicacoes-wrapper .publicacoes__img {
  max-width: 34rem;
  min-width: 18.75rem;
  width: 100%;
  height: 19.25rem;
  position: relative;
}
.publicacoes-wrapper .publicacoes__img .img {
  width: 100%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.publicacoes-wrapper .publicacoes__img .img:hover {
  background: linear-gradient(to bottom, transparent -100%, black 100%) url(intial) !important;
}
.publicacoes-wrapper .publicacoes__img span {
  position: absolute;
  bottom: 0;
  left: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  color: var(--bg-color);
  margin: 0 1.25rem 1.5rem 1.25rem;
  border-top: 0.25rem solid var(--primary-color);
}
.publicacoes-wrapper .publicacoes__links {
  max-width: 33.75rem;
}
.publicacoes-wrapper .publicacoes__links ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media (max-width: 73.3125em) {
  .publicacoes-wrapper .publicacoes__links ul {
    gap: 0.9375rem;
  }
}
.publicacoes-wrapper .publicacoes__link span {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0rem;
}
.publicacoes-wrapper .publicacoes__link a {
  font-style: normal;
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 1.5rem;
  color: var(--txt-color);
}

.footer {
  padding: 4.375rem 0 3.5625rem;
  background-color: var(--fg-color);
  color: var(--bg-color);
}
.footer-wrapper {
  max-width: 69.6875rem;
  margin: 0 auto;
}
.footer__col {
  max-width: 14.8125rem;
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
  margin: 0.9375rem;
}
.footer__col--title {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2.25rem;
  margin: 0;
}
.footer__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 66.875rem;
  padding-top: 1.5625rem;
  margin-top: 2.5rem;
  font-size: 0.8125rem;
  font-weight: normal;
  border-top: 0.0625rem solid #9A9A9B;
}
@media (max-width: 39.9375em) {
  .footer__meta {
    flex-direction: column;
    gap: 0.9375rem;
  }
}
@media (max-width: 73.3125em) {
  .footer__meta {
    padding: 1.5625rem 0.9375rem 0;
  }
}
.footer__meta > div {
  display: inline-block;
}
.footer__meta--rights p {
  margin: 0;
  text-align: center;
}
.footer__meta--developer img {
  margin-left: 0.8125rem;
}
.footer__widget {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: 1.3125rem;
  color: #9a9a9b;
}
.footer__widget--address {
  padding: 0.125rem 0;
}
.footer__widget--contact a {
  color: inherit;
}
.footer__widget--contact a:hover {
  color: var(--primary-color);
  transition: color 0.2s ease-in-out;
}
.footer__widget--socials {
  display: inline;
  margin-right: 0.625rem;
}
.footer__widget--socials .facebook svg .a {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.footer__widget--socials .facebook svg .b,
.footer__widget--socials .facebook svg .c {
  stroke: none;
}
.footer__widget--socials .facebook svg .c {
  fill: #b4b4b1;
}
.footer__widget--socials .facebook svg:hover .c {
  fill: var(--primary-color);
  transition: fill 0.2s ease-in-out;
}
.footer__widget--socials .instagram svg .a {
  fill: #b4b4b4;
}
.footer__widget--socials .instagram svg:hover .a {
  fill: var(--primary-color);
  transition: fill 0.2s ease-in-out;
}
.footer__widget--socials .linkedin svg {
  height: 1.375rem;
  color: #b4b4b4;
}
.footer__widget--socials .linkedin svg:hover {
  color: var(--primary-color);
  transition: color 0.2s ease-in-out;
}
.footer__widget--item {
  padding: 0.4375rem 0;
  border-bottom: 1px solid #9A9A9B;
}
.footer__widget--item a {
  color: #9A9A9B;
}
.footer__widget--item a:hover {
  color: var(--primary-color);
  transition: color 0.2s ease-in-out;
}
.footer__widget--item:first-child {
  padding-top: 0;
}
.footer__widget--item:last-child {
  border: 0;
}
.footer__widget.contact {
  display: flex;
  flex-direction: column;
}
.footer__widget.contact input {
  margin-bottom: 0.625rem;
  height: 2.3125rem;
  padding-inline-start: 0.8125rem;
  background-color: #232325;
  border: transparent;
  color: var(--bg-color);
}
.footer__widget.contact textarea {
  margin-bottom: 1.25rem;
  height: 7.4375rem;
  padding-inline-start: 0.8125rem;
  background-color: #232325;
  border: transparent;
  color: var(--bg-color);
}
.footer__widget.contact button {
  align-self: start;
  height: 2.3125rem;
  padding: 0 0.9375rem;
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: transparent;
}

.logo-and-socials {
  padding: 2.8125rem 6.25rem;
  margin: 2.8125rem;
  margin-right: 6.25rem;
  margin-left: 6.25rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 0.0625rem dashed var(--fg2-color);
  border-bottom: 0.0625rem dashed var(--fg2-color);
}
.logo-and-socials__socials {
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
}
.logo-and-socials__socials a {
  height: 2.1875rem;
  width: 2.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.logo-and-socials__socials a i {
  color: var(--fg-text);
  transition: all 0.3s ease-in-out;
}
.logo-and-socials__socials a:hover {
  background-color: var(--primary-color);
}
.logo-and-socials__socials a:hover i {
  color: var(--bg-color);
}
@media (max-width: 73.3125em) {
  .logo-and-socials {
    flex-direction: column;
    gap: 2.8125rem;
    padding: 2.8125rem 0;
  }
}
@media (min-width: 40em) {
  .logo-and-socials {
    width: 80%;
  }
}

.meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--fg-text);
  font-size: 0.875rem;
}
@media (min-width: 40em) {
  .meta {
    width: 80%;
  }
}
@media (max-width: 73.3125em) {
  .meta {
    flex-direction: column;
    gap: 0.625rem;
    padding: 1.25rem 0;
  }
}
.meta a {
  color: var(--bg-color);
}
.meta a:hover {
  color: var(--primary-color);
}
.meta span {
  text-align: center;
}

.subscribe-form h4 {
  margin-top: 2.1875rem;
  margin-bottom: 0.625rem !important;
}
.subscribe-form label {
  line-height: 1.1875rem !important;
  margin-bottom: 0.625rem;
  font-style: normal;
  font-size: 0.875rem;
  color: var(--fg-text);
}
.subscribe-form form {
  display: flex;
  align-items: center;
  max-width: 23.8125rem;
}
.subscribe-form__input {
  background-color: #232325;
  color: var(--bg-color);
  border: none;
  outline: none;
  padding: 0.5rem 0.3125rem 0.5rem 0.9375rem;
  flex-grow: 1;
  font-family: inherit;
}
.subscribe-form__button {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--primary-color);
  color: var(--bg-color);
  transition: all 0.3s ease-in-out;
}
.subscribe-form__button:hover {
  background-color: var(--bg-color);
  color: var(--primary-color);
}

.contact__title {
  padding: 0.9375rem;
  display: flex;
  justify-content: center;
}
.contact__title h1 {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  color: var(--fg-color);
  text-align: center;
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 0.625rem;
}
.contact__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 56.875rem;
}
.contact__form .form__col {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-width: 26.875rem;
  width: 100%;
  margin: 0 0.625rem;
}
.contact__form .form__col label {
  display: block;
  color: var(--fg-text);
  font-size: 0.9375rem;
}
.contact__form .form__col input,
.contact__form .form__col textarea {
  background-color: #ebebeb;
  border-color: transparent;
  box-shadow: none;
  padding: 0.3125rem;
  margin: 0 !important;
  width: 100%;
}
.contact__form .form__col textarea {
  height: calc(100% - 22px);
}
.contact__form .form__actions {
  margin: 1.875rem 0.625rem;
}
.contact__form .form__actions button {
  background-color: var(--primary-color);
  color: var(--bg-color);
  padding: 0.3125rem 0;
  border-color: transparent;
}

.atuacao {
  background-color: #F5F5F5;
  padding: 35px 0;
}

.team-wrapper {
  margin-bottom: 6.25rem;
}
.team-wrapper .team-header {
  max-width: 90rem;
  min-height: 8.125rem;
  margin: 0 auto;
  background-image: url(../assets/decorators/img-sp.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.team-wrapper .team-header span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4375rem;
  color: var(--bg-color);
  text-shadow: 1px 1px 2px #3a3a3c;
}
.team-wrapper .team {
  max-width: 69.6875rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4.9375rem;
}
.team-wrapper .team__title {
  text-align: center;
  margin: 5rem 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.team-wrapper .team__title span {
  border-bottom: 0.25rem solid var(--primary-color);
}
.team-wrapper .team__card {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-bottom: 0.25rem solid #3a3a3c;
}
@media (max-width: 39.9375em) {
  .team-wrapper .team__card {
    flex-direction: column;
  }
}
.team-wrapper .team__card--img {
  max-height: 368px;
}
.team-wrapper .team__card--info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3.3125rem 3.5rem 2.9375rem 3rem;
  min-height: 23rem;
}
.team-wrapper .team__card--info p,
.team-wrapper .team__card--info a {
  word-break: break-word;
}
.team-wrapper .team__card--info a {
  color: var(--primary-color);
}
@media (max-width: 39.9375em) {
  .team-wrapper .team__card--info {
    gap: 1.5625rem;
    padding: 3.125rem 0.625rem;
  }
}
.team-wrapper .team__card--info .title {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.25rem;
  margin-bottom: 0.3125rem;
}
.team-wrapper .team__card--info .subtitle {
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.625rem;
  margin: 0;
}
.team-wrapper .team__card--info .bio {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.625rem;
  margin: 0;
}
.team-wrapper .team__card--info .mail {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}
@media (max-width: 39.9375em) {
  .team-wrapper .team__card--info .mail {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
.team-wrapper .team__card--info .mail p {
  margin: 0;
  line-height: 1;
}

.office-wrapper .office-header {
  max-width: 90rem;
  min-height: 8.125rem;
  margin: 0 auto;
  background-image: url(../assets/decorators/img-sp.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.office-wrapper .office-header span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4375rem;
  color: var(--bg-color);
  text-shadow: 1px 1px 2px #3a3a3c;
}
.office-wrapper .office {
  max-width: 69.6875rem;
  margin: 0 auto;
  margin-bottom: 4.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.office-wrapper .office__title {
  text-align: center;
  margin: 5rem auto;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.75rem;
  display: inline-block;
  border-bottom: 0.25rem solid var(--primary-color);
}
.office-wrapper .office__card {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  margin: 1.875rem 0;
  border-bottom: 0.25rem solid #3a3a3c;
}
@media (max-width: 39.9375em) {
  .office-wrapper .office__card {
    flex-direction: column;
  }
}
.office-wrapper .office__card--info {
  padding: 3.3125rem 3.125rem 2.5rem 3rem;
}
@media (max-width: 39.9375em) {
  .office-wrapper .office__card--info {
    padding: 1.25rem;
  }
}
.office-wrapper .office__gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.1875rem;
}
.office-wrapper .office__clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.office-wrapper .office__clients--title {
  text-align: center;
  margin: 5rem auto;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.75rem;
  display: inline-block;
  border-bottom: 0.25rem solid var(--primary-color);
}
.office-wrapper .office__clients--logos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.25rem;
}
.office-wrapper .office__clients--logos img {
  max-width: 12.5rem;
  height: auto;
}