:root {
  --primary-color: hsl(0, 100%, 50%);
  --txt-color: hsl(0, 0%, 6%);
  --bg-color: 	hsl(0, 0%, 100%);
  --fg-color: hsl(240, 2%, 18%);
  --fg2-color: hsla(0, 0%, 100%, 0.1);
  --fg-text: hsl(0, 0%, 71%);
}

// Should always use hsl rather than hex or rgb
